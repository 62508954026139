import $http from '@/utitls/request'
/**
 * 获取场景的组态信息
 */
export const GetScenesInfo = (data, headers) => {
    return $http.post('/api/Scenes/GetScenesInfo', data, { headers })
}
/**
 * 获取组态场景填充信息
 */
export const GetScenesFillList = (data) => {
    return $http.get('/api/Scenes/GetScenesFillList', { params: data })
}
/**
 * 分页获取组态场景信息
 */
export const GetScenesPageList = (data) => {
    return $http.get('/api/Scenes/GetScenesPageList', { params: data })
}
/**
 * 新增
 */
export const AddScenes = (data) => {
    return $http.post('/api/Scenes/AddScenes', data)
}
/**
 * 修改
 */
export const EditScenesInfo = (data) => {
    return $http.put('/api/Scenes/EditScenesInfo', data)
}
/**
 * 修改
 */
export const EditScenes = (data) => {
    return $http.put('/api/Scenes/EditScenes', data)
}
/**
 * 删除
 */
export const DeleteScenes = (data) => {
    return $http.delete('/api/Scenes/DeleteScenes', { params: data })
}
/**
 * 复制场景
 */
export const CopyScenes = (data) => {
    return $http.post('/api/Scenes/CopyScenes', data)
}
/**
 * 设置场景权限
 */
export const SetScenesPermission = (data) => {
    return $http.post('/api/Scenes/SetScenesPermission', data)
}